<template>
  <div class="container">
    <el-row class="top_container">
      <el-col class="banner">
        <el-row class="main_banner">
          <el-col class="banner_title hidden-xs-only">{{
            language == 'cn'
              ? bannerList.cn_title
              : language == 'jp'
              ? bannerList.jp_title
              : language == 'en'
              ? bannerList.en_title
              : ''
          }}</el-col>
          <el-col class="banner_title hidden-sm-and-up">{{
            $t('lang.about')
          }}</el-col>
          <el-col class="banner_pic" ref="bannerPic">
            <img v-lazy="bannerList.image" alt="" />
          </el-col>
        </el-row>
        <el-row class="main_desc" v-if="language == 'cn'">
          <p v-for="(item, index) in cnBannerList" :key="index">{{ item }}</p>
        </el-row>
        <el-row class="main_desc" v-if="language == 'jp'">
          <p v-for="(item, index) in jpBannerList" :key="index">{{ item }}</p>
        </el-row>
        <el-row class="main_desc" v-if="language == 'en'">
          <p v-for="(item, index) in enBannerList" :key="index">{{ item }}</p>
        </el-row>
        <el-row class="main_nav">
          <el-col>
            <img src="../assets/img/fast.png" alt="" />
            <span>{{ $t('lang.fast') }}</span>
          </el-col>
          <el-col>
            <img src="../assets/img/loyal.png" alt="" />
            <span>{{ $t('lang.honest') }}</span>
          </el-col>
          <el-col>
            <img src="../assets/img/shape.png" alt="" />
            <span>{{ $t('lang.enterprise') }}</span>
          </el-col>
          <el-col>
            <img src="../assets/img/chart.png" alt="" />
            <span>{{ $t('lang.multielement') }}</span>
          </el-col>
        </el-row>
        <el-row class="main_introduce"></el-row>
      </el-col>
    </el-row>
    <el-row class="middle_container">
      <div class="middle_content">
        <el-col class="company_name">{{ $t('lang.comapny_introduce') }}</el-col>
        <el-col class="company_info">
          <el-row class="company_item">
            <el-col class="item_title">{{ $t('lang.company_name') }}</el-col>
            <el-col class="item_content">{{
              language == 'cn'
                ? companyInfo.cn_company
                : language == 'jp'
                ? companyInfo.jp_company
                : language == 'en'
                ? companyInfo.en_company
                : ''
            }}</el-col>
          </el-row>
          <el-row class="company_item">
            <el-col class="item_title">{{ $t('lang.found_time_name') }}</el-col>
            <el-col class="item_content">{{ companyInfo.founded_time }}</el-col>
          </el-row>
          <el-row class="company_item">
            <el-col class="item_title">{{
              $t('lang.legal_person_name')
            }}</el-col>
            <el-col class="item_content"
              >{{
                language == 'cn'
                  ? companyInfo.cn_position
                  : language == 'jp'
                  ? companyInfo.jp_position
                  : language == 'en'
                  ? companyInfo.en_position
                  : ''
              }}
              {{
                language == 'cn'
                  ? companyInfo.cn_people
                  : language == 'jp'
                  ? companyInfo.jp_people
                  : language == 'en'
                  ? companyInfo.en_people
                  : ''
              }}</el-col
            >
          </el-row>
          <el-row class="company_item">
            <el-col class="item_title">{{ $t('lang.funds_money') }}</el-col>
            <el-col class="item_content">{{
              language == 'cn'
                ? companyInfo.cn_money
                : language == 'jp'
                ? companyInfo.jp_money
                : language == 'en'
                ? companyInfo.en_money
                : ''
            }}</el-col>
          </el-row>
          <el-row class="company_item">
            <el-col class="item_title">{{ $t('lang.address_name') }}</el-col>
            <el-col class="item_content">{{
              language == 'cn'
                ? companyInfo.cn_address
                : language == 'jp'
                ? companyInfo.jp_address
                : language == 'en'
                ? companyInfo.en_address
                : ''
            }}</el-col>
          </el-row>
          <el-row class="company_item">
            <el-col class="item_title">{{ $t('lang.branch_company') }}</el-col>
            <el-col class="item_content">{{
              language == 'cn'
                ? companyInfo.cn_address_two
                : language == 'jp'
                ? companyInfo.jp_address_two
                : language == 'en'
                ? companyInfo.en_address_two
                : ''
            }}</el-col>
          </el-row>
          <el-row class="company_item">
            <el-col class="item_title">{{
              $t('lang.business_field_name')
            }}</el-col>
            <el-col class="item_content">
              <el-row v-for="item in serviceList" :key="item.id">
                <ul>
                  <li>
                    {{
                      language == 'cn'
                        ? item.cn_title
                        : language == 'jp'
                        ? item.jp_title
                        : language == 'en'
                        ? item.en_title
                        : ''
                    }}
                  </li>
                  <li
                    v-for="(itemm, index) in JSON.parse(
                      language == 'cn'
                        ? item.cn_content
                        : language == 'jp'
                        ? item.jp_content
                        : language == 'en'
                        ? item.en_content
                        : ''
                    )"
                    :key="index"
                  >
                    {{ itemm }}
                  </li>
                </ul>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
      </div>
    </el-row>
    <el-row class="bottom_cotainer">
      <div class="bottom_pic">
        <swiper
          ref="mySwiper"
          :options="swiperOptions"
          v-if="bottomBanner.length > 0"
        >
          <swiper-slide v-for="item in bottomBanner" :key="item.id">
            <img :src="item.image" style="width: 100%; height: 100%" alt="" />
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
    </el-row>
  </div>
</template>

<script>
import { homeResource, sourceCompany, homeBanner } from '../api/index'
export default {
  name: 'about',
  props: {},
  data() {
    return {
      swiperOptions: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        slidesPerView: 1,
        // spaceBetween: 40,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
        // Some Swiper option/callback...
      },
      // bannerH: document.body.clientWidth / 2,
      main_desc: {},
      language: window.sessionStorage.getItem('language')
        ? window.sessionStorage.getItem('language')
        : 'jp',
      serviceList: [],
      bannerList: {},
      cnBannerList: [],
      jpBannerList: [],
      enBannerList: [],
      bottomBanner: [],
      companyInfo: {}
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  created() {
    this.getServiceList()
    this.getBanner()
    this.getBottomBanner()
    this.getCompanyInfo()
  },
  mounted() {
    var that = this
    that.picChange()
    window.onresize = () => {
      return (() => {
        that.picChange()
      })()
    }
  },
  destroyed() {
    window.onresize = null
  },
  watch: {},
  methods: {
    picChange() {
      var banner = this.$refs.bannerPic
      banner.$el.style.width = '100%'
      banner.$el.style.height = document.body.clientWidth / 4 + 'px'
    },
    getServiceList() {
      homeResource({ type: 3 })
        .then(res => {
          if (res.code == '20000') {
            this.serviceList = res.data.list
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getBanner() {
      homeResource({ type: 5 })
        .then(res => {
          if (res.code == '20000') {
            this.bannerList = res.data.list[0]
            // 根据换行符，实现文字分段
            this.cnBannerList = res.data.list[0].cn_content.split('\r\n')
            this.jpBannerList = res.data.list[0].jp_content.split('\r\n')
            this.enBannerList = res.data.list[0].en_content.split('\r\n')
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getBottomBanner() {
      homeBanner({ type: 2 })
        .then(res => {
          if (res.code == '20000') {
            this.bottomBanner = res.data.list
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getCompanyInfo() {
      sourceCompany({ document: 1 })
        .then(res => {
          if (res.code == '20000') {
            this.companyInfo = res.data.info
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 768px) {
  .container {
    width: 100%;
    .top_container {
      width: 100%;
      .banner {
        display: flex;
        flex-direction: column;
        align-items: center;
        .main_banner {
          width: 100%;
          margin-top: 65px; //nav高132px
          .banner_title {
            text-align: center;
            color: #525252;
            font-size: 48px;
            line-height: 67px;
          }
          .banner_pic {
            margin-top: 60px;
            // height: 628px;
            // width: 100%;
            img {
              height: 100%;
              width: 100%;
            }
          }
        }
        .main_desc {
          width: 60%;
          margin: 94px 0 84px 0;
          p {
            text-align:justify;
            color: #525252;
            font-size: 16px;
            line-height: 22px;
            text-indent: 2em;
          }
        }
        .main_nav {
          width: 60%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 41px;
          margin-bottom: 94px;
          .el-col {
            display: flex;
            justify-content: center;
            span {
              color: #000000;
              font-size: 28px;
              font-weight: 600;
              line-height: 38px;
            }
            img {
              margin-right: 19px;
            }
            &:nth-of-type(1) {
              img {
                width: 68px;
                height: 39px;
              }
            }
            &:nth-of-type(2) {
              img {
                width: 40px;
                height: 35px;
              }
            }
            &:nth-of-type(3) {
              img {
                width: 38px;
                height: 38px;
              }
            }
            &:nth-of-type(4) {
              img {
                width: 48px;
                height: 41px;
              }
            }
          }
        }
      }
    }
    .middle_container {
      display: flex;
      justify-content: center;
      width: 100%;
      background-color: rgba(209, 201, 199, 0.25);
      .middle_content {
        width: 60%;
      }
      .company_name {
        margin: 70px 0 84px 0;
        text-align: center;
        color: #000000;
        font-size: 36px;
        font-weight: 500;
        line-height: 38px;
      }
      .company_info {
        .company_item {
          display: flex;
          margin-bottom: 20px;
          .item_title {
            text-align: left;
            width: 200px;
            color: #525252;
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
          }
          .item_content {
            padding-left: 85px;
            color: #525252;
            font-size: 20px;
            line-height: 28px;
            ul {
              margin-bottom: 17px;
              li {
                color: #525252;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 1px;
                line-height: 38px;
                &:nth-of-type(1) {
                  color: #525252;
                  font-size: 20px;
                  font-weight: 600;
                  line-height: 28px;
                }
              }
            }
          }
        }
      }
    }
    .bottom_cotainer {
      width: 100%;
      .bottom_pic {
        width: 100%;
        // padding: 68px 0;
        .swiper-container {
          position: relative;
          width: 1024px;
          height: 512px;
          background: pink;
          .swiper-slide {
            width: 100%;
            line-height: 200px;
            background: yellowgreen;
            color: #000;
            font-size: 16px;
            text-align: center;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .top_container {
      width: 80%;
      .banner {
        .main_banner {
          margin-top: 58px;
          .banner_title {
            text-align: center;
            color: #000000;
            font-size: 16px;
            font-weight: 500;
            line-height: 38px;
          }
          .banner_pic {
            margin-top: 13px;
            // height: 169px;
            // width: 100%;
            img {
              height: 100%;
              width: 100%;
            }
          }
        }
        .main_desc {
          margin: 30px 0 40px 0;
          p {
            text-align:justify;
            color: #525252;
            font-size: 12px;
            text-indent: 2em;
            line-height: 17px;
          }
        }
        .main_nav {
          margin-bottom: 40px;
          .el-col {
            width: 50%;
            display: flex;
            margin-bottom: 18px;
            justify-content: center;
            align-items: center;
            span {
              color: #000000;
              font-size: 16px;
              font-weight: 600;
              line-height: 38px;
            }
            img {
              margin-right: 15px;
            }
            &:nth-of-type(1) {
              img {
                width: 45px;
                height: 26px;
              }
            }
            &:nth-of-type(2) {
              img {
                width: 30px;
                height: 27px;
              }
            }
            &:nth-of-type(3) {
              img {
                width: 28px;
                height: 28px;
              }
            }
            &:nth-of-type(4) {
              img {
                width: 29px;
                height: 25px;
              }
            }
          }
        }
      }
    }
    .middle_container {
      display: flex;
      justify-content: center;
      width: 100%;
      background-color: rgba(209, 201, 199, 0.25);
      .middle_content {
        width: 80%;
      }
      .company_name {
        text-align: center;
        margin: 36px 0 45px 0;
        color: #000000;
        font-size: 16px;
        font-weight: 500;
        line-height: 38px;
      }
      .company_info {
        .company_item {
          margin-bottom: 18px;
          .item_title {
            text-decoration: underline;
            color: #525252;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
          }
          .item_content {
            color: #525252;
            font-size: 12px;
            line-height: 17px;
            padding-top: 12px;
            ul {
              margin-bottom: 17px;
              li {
                color: #525252;
                font-size: 11px;
                font-weight: 500;
                letter-spacing: 0.69px;
                line-height: 18px;
                margin-bottom: 18px;
                &:nth-of-type(1) {
                  text-decoration: underline;
                  color: #525252;
                  font-size: 12px;
                  font-weight: 600;
                  line-height: 17px;
                }
              }
            }
          }
        }
      }
    }
    .bottom_cotainer {
      width: 100%;
      .bottom_pic {
        // margin: 50px 0 69px 0;
        // width: 80%;
        .swiper-container {
          position: relative;
          width: 100%;
          height: 256px;
          background: pink;
          .swiper-slide {
            width: 100%;
            line-height: 200px;
            background: yellowgreen;
            color: #000;
            font-size: 16px;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
